import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx50And200DayMaChartTemplate from '@/templates/crypto/xxx-50-200-day-moving-average-chart';

export default function Ethereum50And200DayMaChart({
  location,
}: GatsbyPageProps) {
  return (
    <Xxx50And200DayMaChartTemplate
      coinAbbreviation="ETH"
      coinFullName="Ethereum"
      token={Token.ETHUSDT}
      location={location}
      articleId={ArticleList.ETH_50_200_DAY_MOVING_AVERAGE_CHART}
    ></Xxx50And200DayMaChartTemplate>
  );
}
